// import React from "react";
// import axios from 'axios';

const BooksData = [
    {
    id:"1",
    title: "VALUES, MORAL AND ETHICS EDUCATION: AN INTRODUCTORY APPROACH FOR UNIVERSITIES AND COLLEGES OF EDUCATION",
    author:"Bernedette U. Cornelius-Ukpepi, PhD",
    img:"values.jpg",
    year:"2019",
    pdf:"values.pdf",
    text:" This book on values, morals and ethics is written as an introductory text for students pursuing B.Ed, M.Ed and Ph.D in Elementary Education as well as N.C.E. students in colleges of education. The text constitutes a significant portion of the course content of values and valuing in teaching in elementary education both in the university, colleges of education and in the primary and secondary schools.This introductory text has addressed Value education, moral education and ethics education. Theories of both specific areas have been reviewed with their implications for clarity."        
},
    {
        id:"2",
        title: "PSYCHOLOGY AND PSYCHIATRY (ABNORMAL PSYCHOLOGY)",
        author:"Emilia James Oyira, PhD",
        img:"psychology and psychiatry.jpg",
        year:"2019",
        pdf:"psychology and psychiatry.pdf",
        text: "As a teacher and active researcher, I have a number of goals for this book. I want the first edition of Psychology/Psychiatry to make the field of these schools come alive for them. To accomplish this objective, throughout the book, I have sought to link the material to students' life experiences, so that they can integrate their new knowledge into an existing framework to enhance its retention and use after the course is completed. I have written this book as a simplified text for schools of nursing, Health technology, colleges, and university students who are offering courses in psychology and psychiatry in the fields of education and medical sciences. The topics covered in this first edition are aimed at meeting the needs of students who are having first time detailed exposure to psychology and psychiatry."
              
        },
    {
            id:"3",
            title: "Understanding Teaching",
            author:"Professor Omoogun, Ajayi C.",
            img:"generic.jpg",
            year:"2022",
            pdf:"understanding teaching.pdf",
            text:"Human capital development constitutes the peak product of quality instructional service delivery by quality teachers. Essentially, quality teachers are indispensable and irreplaceable human instruments and requisites for the creation of intellectual assets germane to nation building and development. Professor Ajayi Omoogun, the author of this textbook accentuates logically, coherently and plausibly that quality teaching which culminates in sustainable knowledge acquisition must not be driven by teachers but by learners. The author asserts emphatically that 21st century budding educators and student teachers must implicitly submit themselves to this extant and dynamic pedagogical training process to acquire relevant knowledge, understanding and skills for efficacious instructional service delivery. This intelligent postulation of the author debunks the misconception that without requisite pedagogical training, possessors of rich reservoir of knowledge and a quantum of experiences have the proclivity, capacity and competencies to teach effectively and productively."        
    },
    {
        id:"4",
        title: "Handbook for Educating Persons with Visual Impairmente Pictoral Book",
        author:"Nanjwan, J. D. PhD & Innocent Ushi Igba",
        img:"generic.jpg",
        year:"2019",
        pdf:"handbook.pdf",
        text:" In a world where learning is a continuous process, this Handbook for Educating Persons with Visual Impairment is no doubt a rich and compelling document for anyone interested in the education of children with visual impairment to not only have on his or her shelve but to read and digest. Containing six well-articulated chapters, the handbook provides an insight into what can be expected when dealing with children living with visual impairment in practically all given scenarios whether at home or in educational settings. It covers such areas as eye education and visual impairment with a clear and well labeled diagram of the eye, common causes and possible prevention of visual impairment, orientation and mobility for children with visual impairment and assistive technology for persons with visual impairment. Other areas included in the handbook is a real life report on evaluation and examination of the eye as revealed after a study of not less than 17 cases of eye conditions during an internship period as well as an exposition on the specific needs of children with visual impairment."        
    },
    {
        id:"5",
        title: "Contemporary Issues and Concepts in Business Education",
        author:"G.C.E. Duruamaku-Dim",
        img:"generic.jpg",
        year:"2019",
        pdf:"contemporary issues.pdf",
        text:"The initiative behind this book, Contemporary Issues and Concepts in Business Education is based on a profound interest in and respect for Education, Business Education and Students. It is also based on experiences gained from years of teaching which the contributors had the privilege of sharing as implementers of Business Education Curriculum. These experiences have been acquired not only in the classroom but also through working with students. Nigeria is endowed with adequate business resources but has experienced acute and preponderance of business failure. Nigeria needs more business knowledge for the increasing population to prevent business failures and unemployment. Realizing this need, the government has embarked on a number of programmes aimed at securing business success and sustainability. The introduction of Business Studies, business subjects and Business Education at Junior Secondary School, Senior Secondary and Tertiary Educational institutions respectively, all aiming at increasing business knowledge and success on long term basis is one of the approaches in this regard."        
    },
    {
        id:"6",
        title: "Like HEAVEN",
        author:"Eudoria John-Anari",
        img:"like heaven.jpg",
        year:"2019",
        pdf:"like heaven.pdf",
        text:" It was a beautiful February day in Ogoja; the kind of day that starts one with smiles and hope. Located in the northern region of Cross River State in the south-southern fringes of Nigeria, Ogoja experiences a benign Savanna climate, with a dry and hot harmattan. It was therefore a privilege to have such a cool breezy day in the second month. The winds were not blowing hard and there were clouds in the sky, too far above to hold any rains but enough to reduce the intensity of the sun. It was a day that made Eyare Ayop happy. She paused to ponder, while on her morning chores."        
    },
    {
        id:"7",
        title: "READINGS ON COUNSELLING AND SOCIAL INCLUSION: A COUNSELLOR COMPANION",
        author:"University of Calabar",
        img:"generic.jpg",
        year:"2019",
        pdf:"PROCEEDING PRILIMINARY2.pdf",
        text:" I appreciate the Counselling Association of Nigeria (CASSON) for giving me another opportunity to serve in the capacity of the Editor to this book. Appreciated also are the authors and all those who contributed to the success of the endeavour. The printer, Sunny-Tech Link and Logistic Concept is acknowledged for its diligence. - Ali Mburza"        
    },
    {
        id:"8",
        title: "VALUES, MORAL AND ETHICS EDUCATION: AN INTRODUCTORY APPROACH FOR UNIVERSITIES AND COLLEGES OF EDUCATION",
        author:"Bernedette U. Cornelius-Ukpepi, PhD",
        img:"generic.jpg",
        year:"2019",
        pdf:"values.pdf",
        text:" This book on values, morals and ethics is written as an introductory text for students pursuing B.Ed, M.Ed and Ph.D in Elementary Education as well as N.C.E. students in colleges of education. The text constitutes a significant portion of the course content of values and valuing in teaching in elementary education both in the university, colleges of education and in the primary and secondary schools.This introductory text has addressed Value education, moral education and ethics education. Theories of both specific areas have been reviewed with their implications for clarity."        
    },
        {
            id:"9",
            title: "PSYCHOLOGY AND PSYCHIATRY (ABNORMAL PSYCHOLOGY)",
            author:"Emilia James Oyira, PhD",
            img:"psychology and psychiatry.jpg",
            year:"2019",
            pdf:"psychology and psychiatry.pdf",
            text: "As a teacher and active researcher, I have a number of goals for this book. I want the first edition of Psychology/Psychiatry to make the field of these schools come alive for them. To accomplish this objective, throughout the book, I have sought to link the material to students' life experiences, so that they can integrate their new knowledge into an existing framework to enhance its retention and use after the course is completed. I have written this book as a simplified text for schools of nursing, Health technology, colleges, and university students who are offering courses in psychology and psychiatry in the fields of education and medical sciences. The topics covered in this first edition are aimed at meeting the needs of students who are having first time detailed exposure to psychology and psychiatry."
                  
            },
        {
                id:"10",
                title: "VALUES, MORAL AND ETHICS EDUCATION: AN INTRODUCTORY APPROACH FOR UNIVERSITIES AND COLLEGES OF EDUCATION",
                author:"Bernedette U. Cornelius-Ukpepi, PhD",
                img:"generic.jpg",
                year:"2022",
                pdf:"values.pdf",
                text:"This book on values, morals and ethics is written as an introductory text for students pursuing B.Ed, M.Ed and Ph.D in Elementary Education as well as N.C.E. students in colleges of education. The text constitutes a significant portion of the course content of values and valuing in teaching in elementary education both in the university, colleges of education and in the primary and secondary schools. This introductory text has addressed Value education, moral education and ethics education. Theories of both specific areas have been reviewed with their implications for clarity. The text is replete with many examples of values and moral values as well as moral dilemmas where two conflicting values can be dictated for children to make informed decisions."        
        },
        {
            id:"11",
            title: "FAHSANU JOURNAL JOURNAL OF THE ARTS/HUMANITIES Volume 1, Number 1",
            author:"Faculties of Arts/Humanities Scholars' Association of Nigerian Universities.",
            img:"generic.jpg",
            year:"2018",
            pdf:"fahsanu1.pdf",
            text:" FAHSANU JOURNAL is a referred interdisciplinary journal of the Arts/Humanities published at least twice a year by FAHSANU. Articles of not more than 15 A4-sized pages are welcomed from the Arts/Humanities and related disciplines. Papers submitted should conform strictly with the MLA style sheet of latest edition at all given times. However, in this edition, MLA and APA were allowed being the very first. The first publication in every year will be papers presented at the FAHSANU conference while the second will be received from a general call for papers by FAHSANU. Papers must be written in double line spacing."        
        },
        {
            id:"12",
            title: "FAHSANU JOURNAL JOURNAL OF THE ARTS /HUMANITIES VOLUME 1, NUMBER 2",
            author:"Faculties of Arts/Humanities Scholars' Association of Nigerian Universities.",
            img:"generic.jpg",
            year:"2019",
            pdf:"fahsanu2.pdf",
            text:" FAHSANU JOURNAL is a referred interdisciplinary journal of the Arts/Humanities published at least twice a year by FAHSANU. Articles of not more than 15 A4-sized pages are welcomed from the Arts/Humanities and related disciplines. Papers submitted should conform strictly with the MLA style sheet of latest edition at all given times. However, in this edition, MLA and APA were allowed being the very first. The first publication in every year will be papers presented at the FAHSANU conference while the second will be received from a general call for papers by FAHSANU. Papers must be written in double line spacing."        
        },
        {
            id:"13",
            title: "The Beautiful Garment from Babylon",
            author:"Efiom Henshaw",
            img:"generic.jpg",
            year:"2019",
            pdf:"beautiful garment.pdf",
            text:"We all do have desires in life. It is good to desire that which is good or that which could positively change your life or the lives of others. Desires could at times serve as the bedrock of discoveries and inventions. Desires could either be good or evil. Every evil desire takes you away from the presence of God. When desires are taken to the extreme, it becomes bad. Any desire that leads to a partial or total disobedience to God is bad. Naturally, we desire or lust after the things we see. The natural man mostly lusts after things that will provoke God to anger. Lust is a powerful weapon in the hand of the devil. One of the ways the devil tempts people to eventually bring them down to destruction is through sinful lust. The Bible categorises the sinful desires in the world as the lust of the flesh, the lust of the eyes and the pride of life. Every man going through the journey of life will be tempted with lust. On a daily basis, the devil manipulates our thoughts and desires to tempt us. The moment you give in to these sinful desires, it leads to sin. When sin is allowed to grow, it leads to death. A good number of Biblical characters either entered into trouble or lost their lives simply by following through the sinful desires of their hearts. In Joshua 7:20-26, Achan lusted after a set of beautiful garment from Babylon and some amount of money contrary to God‟s injunction."        
        },
        {
            id:"14",
            title: "DIMENSIONS OF NATURAL RESOURCES MANAGEMENT IN THE ENVIRONMENT",
            author:"Elizabeth E. Andrew-Essien & Enoabasi D. Anwana",
            img:"generic.jpg",
            year:"2019",
            pdf:"dimensions.pdf",
            text:" The natural environment is replete with resources that propel the geo-interface between environmental components to influence prospects for human growth and development. The advantageous outcomes of resource development in global economies have over the years promoted the socio-economic forces of demand and supply benefits that drive incessant resource utilization processes. However, the emerging environmental challenges that occur across micro-scales as a result of accelerated population growth, unregulated economic growth, consumerism and environmental insensitivity and behaviour indicate changing regimes in resource use and management contexts that affect the prospects for resource sustainability in time to come. The book, Dimensions of Natural Resources Management, explores the various attendant processes, effects and consequences of human options in the natural environment with a view to fostering in-depth understandings of human behaviour in relation to environmental resources management. Emphasis is placed on the various dimensions of the environment that cut across broad- based connections such as indigenous knowledge systems, ecology and wildlife, oceans, governance, social justice, climate change protected areas, resource development, resource sustainability and interventions. The rich knowledge frontier of this book is relevant to environmental managers, scholars, policy makers and planners, as well as the general public."        
        },
        {
            id:"15",
            title: "VALUES, MORAL AND ETHICS EDUCATION: AN INTRODUCTORY APPROACH FOR UNIVERSITIES AND COLLEGES OF EDUCATION",
            author:"Bernedette U. Cornelius-Ukpepi, PhD",
            img:"generic.jpg",
            year:"2022",
            pdf:"values.pdf",
            text:"This book on values, morals and ethics is written as an introductory text for students pursuing B.Ed, M.Ed and Ph.D in Elementary Education as well as N.C.E. students in colleges of education. The text constitutes a significant portion of the course content of values and valuing in teaching in elementary education both in the university, colleges of education and in the primary and secondary schools. This introductory text has addressed Value education, moral education and ethics education. Theories of both specific areas have been reviewed with their implications for clarity. The text is replete with many examples of values and moral values as well as moral dilemmas where two conflicting values can be dictated for children to make informed decisions."        
    },
    {
        id:"16",
        title: "FAHSANU JOURNAL JOURNAL OF THE ARTS/HUMANITIES Volume 1, Number 1",
        author:"Faculties of Arts/Humanities Scholars' Association of Nigerian Universities.",
        img:"generic.jpg",
        year:"2018",
        pdf:"fahsanu1.pdf",
        text:" FAHSANU JOURNAL is a referred interdisciplinary journal of the Arts/Humanities published at least twice a year by FAHSANU. Articles of not more than 15 A4-sized pages are welcomed from the Arts/Humanities and related disciplines. Papers submitted should conform strictly with the MLA style sheet of latest edition at all given times. However, in this edition, MLA and APA were allowed being the very first. The first publication in every year will be papers presented at the FAHSANU conference while the second will be received from a general call for papers by FAHSANU. Papers must be written in double line spacing."        
    },
    {
        id:"17",
        title: "SPECIAL NEEDS EDUCATION Volume 4",
        author:"THE DEPARTMENT OF SPECIAL EDUCATION UNIVERSITY OF CALABAR, CALABAR-NIGERIA",
        img:"generic.jpg",
        year:"2019",
        pdf:"understanding teaching.pdf",
        text:" Special Needs Education is published by the Department of Special Education, University of Calabar, Calabar Cross River State, Nigeria."        
    },
    {
        id:"18",
        title: "INTRODUCTION TO STRATEGIC STUDIES AND CIVIL-MILITARY RELATIONS",
        author:"Michael Omang Bonchuk, Ph.D",
        img:"generic.jpg",
        year:"2019",
        pdf:"civil military.pdf",
        text:"The motivation for writing this book emanated from the need to provide students with a text that will enable them to understand the subject matter of strategic studies and civil-military relations in a democratic order. It also intends to fill the gaps on the dearth of books that reflect contemporary strategies in the fight against terrorism, insurgency, civil control of the military, including changes in geo- strategic environment and the fungibility of military force and its role in achieving the ends of policy. To this end, the book analyzes the phenomenon of war and its conduct in the hope that students will have a better grasp of the importance and role of military force as a process of government and underscore the misconception that strategic studies is synonymous with international politics."        
    },
    {
        id:"19",
        title: "A SIMPLIFIED APPROACH FOR SCHOLARS IN THE ARTS",
        author:"Esekong Andrew-Essien",
        img:"generic.jpg",
        year:"2019",
        pdf:"simplified approach.pdf",
        text:"Having taught Research Methods for many years and conducted internal and external examinations at various levels in universities, I have come to see that understanding some aspects of research methodology is still a challenge to many. To address this problem, it is necessary to offer alternative approaches towards explaining the research process. Each approach should offer a different flavor or colouration, either in the structure, style, language or illustrations in use. Chances are that an ardent learner will latch on an approach to begin to gain understanding of what was hitherto difficult. From this perspective of reasoning, there can never be too many resources on Research Methods as long as each work approaches the subject innovatively."        
    },
    {
        id:"21",
        title: "RESEARCH METHODS",
        author:"Esekong Andrew-Essien",
        img:"generic.jpg",
        year:"2019",
        pdf:"Research Methods.pdf",
        text:"Having taught Research Methods for many years and conducted internal and external examinations at various levels in universities, I have come to see that understanding some aspects of research methodology is still a challenge to many. To address this problem, it is necessary to offer alternative approaches towards explaining the research process. Each approach should offer a different flavor or colouration, either in the structure, style, language or illustrations in use. Chances are that an ardent learner will latch on an approach to begin to gain understanding of what was hitherto difficult. From this perspective of reasoning, there can never be too many resources on Research Methods as long as each work approaches the subject innovatively."        
    },
    {
        id:"22",
        title: "HUMAN INFLUENCE ON THE ENVIRONMENT: INTERACTIONS, CONFLICTS AND SYNERGIES",
        author:"Elizabeth E. Andrew-Essien",
        img:"generic.jpg",
        year:"2019",
        pdf:"human influence.pdf",
        text:"The connectivity that exists between the physical and human environment is intricate and complex with attendant derivatives. The natural and built-up environment collectively functions as a system to enhance geo-chemical processes and ecosystem services. They also combine to provide food resources, regulate climatic variables, support nutrient cycling and offer cultural and recreational benefits to biotic organisms. In particular, human generations have utilized the physical environment to reap attendant environmental, cultural, socio-economic and political benefits. In the same vein, governments of countries have continued to explore the resources of the earth for various benefits, leaving the environment vulnerable and in a dilemma."        
    }
];

export default BooksData;