import React, { useState } from 'react';


const Pagination = ({totalNumberOfPosts, postsPerPage, setCurrentPage, currentPage}) => {
    let pages = [];
   


    for(let i = 1; i<=Math.ceil(totalNumberOfPosts/postsPerPage); i++){
        pages.push(i);
    }
    // console.log(pages);
    const paginate =(page)=>{
        setCurrentPage(page)
        console.log(currentPage)
    }

  return (
    <div className='page-btns'>
        {
            pages.map((page, index)=>{
                return (<>
                    <button key={index} className={currentPage === page? "page-btn" : "page-btn page-selected"} onClick = {() => paginate(page)}>{page} </button>
                    
                    </>
                )
            })
        }
    </div>
  )
}

export default Pagination;
