import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import {MdOutlineClose} from "react-icons/md"
import Logo from '../../assets/logo.png';
import './NavBar.css';

const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () =>{
        setShowMenu(prevState => prevState = !prevState)
    }
    return (
        <nav className='nav-container'>
            <div onClick={() => setShowMenu(false)} className='logo'>
            <img src={Logo} width='40px'/>

                <h2>SunnyTech</h2>
                </div>
            <ul className={showMenu?'show-menu menu': 'menu'}>
                <li>
                    <NavLink onClick={() => setShowMenu(false)} className={({isActive}) => isActive? 'active-link': ''} to='/'>Home</NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setShowMenu(false)} className={({isActive})  => isActive? 'active-link': ''} to='/About'>About</NavLink>
                </li> 
                <li>
                    <NavLink onClick={() => setShowMenu(false)} className={({isActive})  => isActive? 'active-link': ''} to='/Contact'>Contact</NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setShowMenu(false)} className={({isActive}) => isActive? 'active-link': ''} to='/JobsGallery'>Jobs Gallery</NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setShowMenu(false)} className={({isActive}) => isActive? 'active-link': ''} to='/services'>Our Services</NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setShowMenu(false)} className={({isActive}) => isActive? 'active-link': ''} to='/OurTeam'>Our Team</NavLink>
                </li> 
                
                <li>
                    <NavLink onClick={() => setShowMenu(false)} className={({isActive}) => isActive? 'active-link': ''} to='/Booklist'>Booklist</NavLink>
                </li> 
                  
                
            
            </ul>
            <div onClick={toggleMenu} className='toggle'>
             {showMenu?<MdOutlineClose/>:<FaBars />}
            </div>
        </nav>
    )
}
export default NavBar;