import { Link } from "react-router-dom";
import React from 'react';
import { useState } from "react";
import Books from '../Books';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai';

const Book = ()=>{
    const [index, setIndex] = useState(0);

    const nextHandler = ()=>{
        setIndex(prev => prev + 1);
        if(index >= Books.length-1){
            setIndex(0)
            console.log(index)
        }
    }
    const prevHandler = ()=>{
        setIndex(prev => prev - 1);
        if(index <= 0){
            setIndex(Books.length-1)
            console.log(index)

        }
    }
    const {id, title, text, author, year, download, img} = Books[index];

    return(
        <>
        <div key={id} className="book-container">
            <img src={`./images/gallery/${img}`} alt="" className="book-img" />
            <div className="book-details">
                <div className="book-top">
                    <h3 className="book-title">{title}</h3>
                    <p className="book-text">{text}</p>
                </div>
                <div className="book-bottom">
                <hr />

                    <div className="meta">
                        <small className="author">{author}</small>
                        <small className="year">{year}</small>
                    </div>
                    
                    <button>
                        <Link to="/" className="download">Download {title}</Link>
                    </button>
            
                </div>
            </div>
            <div className="decor"></div>
        </div>
        <div className='btns'>
            <button onClick={prevHandler} className='prev-btn'><AiOutlineArrowLeft/><span>Previous</span></button>
            <button onClick = {nextHandler} className='next-btn'><span>Next</span><AiOutlineArrowRight/></button>                            
        </div>
        </>
    )
}
export default Book;