import React from 'react';
import Header from '../../Components/Header';
import headerImage from '../../assets/headerImages/header1.jpg';
import {Link} from 'react-router-dom';
import '../about/About.css';


// Sunny-Tech Link and Logistic Concept is an indigenous Printing/Publishing
// company with its Head Office and Operational Base at: 94 White House Street,
// Calabar – Nigeria. At Sunny-Tech Link and Logistic Concept, printing/publishing
// jobs are carried out with the highest level of professionalism with speedy
// completion while ensuring clients’ optimal satisfactions through our experienced

// personnel/expertise.

// The company prides itself as one of the fastest growing publishing companies in
// Calabar (The Canan City, located in the South-South region of the country) due to
// her high quality and speedy delivery of jobs from start to finish which has been the

// hallmark of her successes.

// The company policies include: CONFIDENTIALITY, HEALTH, SAFETY, AND

// ENVIRONMENTAL PROTECTION POLICY

// OUR VISION

// Delivery of high quality finished products to our teaming and valuable clients

// across the globe.
// OUR MISSION

// To pursue vigorously our vision and become one of the best (if not the best)

// printing/publishing companies in the world over.

// Our management Team includes:
// MD/CEO
// Sunday M. Otiko
// B.Sc. (Calabar), PGDLIS (Calabar), Dip. Info. Tech.
// General Manager:
// Mr. Godwin Ushie Mathias
// B.Ed (Calabar); Dip. Info Tech, SSCE
// Accountant
// Mr. Paul Eze
// HND Accounting , SSCE
// Editorial Team
// Mr. Ubi E. Onen - B.A., English &amp; Lit. Studies
// Mr. Itomo Fidelis Ebong - B. A. English &amp; Lit. Studies

// Graphic Designers
// Mr. Godwin Okon Esidor
// Mrs Emah Mmenyene Bassey
// Kord Machine Operators
// Mr. Godwin Essien Ekpe – HOD
// Mr Ade Sunday Ogundele
// Mr. Friday F. Thomas
// Cutting Machine Operator
// Mr. Udeme Michael Umo
// Lithographer
// Mr. Effiong Edwin Albert




const Services = () => {
    return (
        <>
        <Header 
        title="Our Services"
        img={headerImage}
    >
        
        
        <p className="h-text">
        We deliver creative Print & Marketing Solutions that make our clients successful. We keep our promises, provide creative solutions, and achieve our client’s goals.        </p>
        <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

    </Header>   
<div className='about-container'>
    <div className='about-wrapper'>
        <div className='about-header'>
            <div className='strike-through'>

            </div>
            <div className='about-title services-title'>
                <h2>Our Services</h2>
                
            </div>
            <small className='small-text'>
                Service with Integrity… Giving Back
                </small>
                
                        <div className='map'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1581.1103334030809!2d8.32124174394795!3d4.947734691426794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1067863cef3e4bcd%3A0xe26b9c0e4ce551d7!2s24%20White%20House%20Street%2C%20540281%2C%20Calabar%2C%20Cross%20River!5e0!3m2!1sen!2sng!4v1680244707860!5m2!1sen!2sng"></iframe>
                        </div>
                        <small className='small'>
                            We are located at #94 WhiteHouse Street, Calabar, Cross River State.
                        </small>
                        
        </div>
        <div className='about-body'>
            <div className='segment'>
            <h2 className='small-text'>Branding Services</h2>

<p>
    At SunnyTech, we understand that effective branding is crucial for any business looking to succeed in today's competitive market. That's why we offer comprehensive branding services that can help your business establish a unique identity and stand out from the crowd.

    Our team of branding experts works closely with you to understand your business and target audience, and develop a custom branding strategy that meets your specific needs. We specialize in creating compelling brand messaging, designing memorable logos and visual identities, and developing cohesive brand guidelines that ensure consistency across all channels.

    From start-ups to established businesses, we have the expertise and experience to help you achieve your branding goals and take your business to the next level. Let us help you build a strong brand that resonates with your audience and drives long-term business success.
</p>
   <div className='service-img'> <img src='./images/gallery/job1.jpeg' /></div>
            </div>
       
        
        <div className='segment'>
        <h2 className='small-text'>Graphics Design Services</h2>
        <p>

        At SunnyTech, we offer high-quality printing and publishing services that can help you bring your ideas to life. Our state-of-the-art printing technology and experienced team of professionals can handle projects of any size, from small print runs to large-scale publishing projects.

We specialize in a range of printing services, including offset, digital, and large format printing, and we can work with a variety of materials to meet your specific needs. Our publishing services include book printing, design, editing, and distribution, ensuring that your book is produced to the highest standards.

With a commitment to quality, customer satisfaction, and timely delivery, SunnyTech is your trusted partner for all your printing and publishing needs. Contact us today to learn more about how we can help you bring your vision to life.        </p>
<div className='service-img'> <img src='./images/gallery/22.jpeg' /></div>

        </div>
        <div className='segment'>
        <h2 className='small-text'>Printing and Publishing Services</h2>
        <p>

        At SunnyTech, we offer high-quality printing and publishing services that can help you bring your ideas to life. Our state-of-the-art printing technology and experienced team of professionals can handle projects of any size, from small print runs to large-scale publishing projects.

We specialize in a range of printing services, including offset, digital, and large format printing, and we can work with a variety of materials to meet your specific needs. Our publishing services include book printing, design, editing, and distribution, ensuring that your book is produced to the highest standards.

With a commitment to quality, customer satisfaction, and timely delivery, SunnyTech is your trusted partner for all your printing and publishing needs. Contact us today to learn more about how we can help you bring your vision to life.        </p>
<div className='service-img'> <img src='./images/gallery/job11.jpeg' /></div>

        </div>
        <div className='segment'>
            <h2 className='small-text'>Promo Items - Sourvenirs</h2>

<p>
    At SunnyTech, we understand that effective branding is crucial for any business looking to succeed in today's competitive market. That's why we offer comprehensive branding services that can help your business establish a unique identity and stand out from the crowd.

    Our team of branding experts works closely with you to understand your business and target audience, and develop a custom branding strategy that meets your specific needs. We specialize in creating compelling brand messaging, designing memorable logos and visual identities, and developing cohesive brand guidelines that ensure consistency across all channels.

    From start-ups to established businesses, we have the expertise and experience to help you achieve your branding goals and take your business to the next level. Let us help you build a strong brand that resonates with your audience and drives long-term business success.
</p>
   <div className='service-img'> <img src='./images/gallery/26.jpeg' /></div>
            </div>
     
            <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

        </div>
        </div>
        
</div>
    
    </>
    )
}
export default Services;