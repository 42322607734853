import React from 'react';
import {Outlet} from 'react-router-dom';
import NavBar from './adminComponents/NavBar';


const AdminLayout = () => {
    return (
        <>
            <NavBar />
            {/* <Header /> */}
            <Outlet />
            {/* <Footer /> */}
        </>
    

    )
}
export default AdminLayout;