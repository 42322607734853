import React, { useEffect, useState } from 'react';
import { FaPortrait} from 'react-icons/fa';
import '../Pages/contact/Contact.css';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';

const Register = () => {
    const [userData, setUserData] = useState({});
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate();
    const createUser = (e) =>{
        e.preventDefault();

        
            axios.post('http://api.sunnytechlink.com/register', userData)
            .then(res => {
                if(res.data.dataStatus === "ok"){
                    // navigate('/admin/login')
                    setSuccess(true)
                    console.log(res.data.statusMsg)

                }else{
                    alert("Error: Could not communicate with the server")

                }

            })
            .then(err => console.log(err));

        
    }
    return (
           <>
            <div className='about-container'>
                <div className='about-wrapper xx'>
                    <div className='about-header'>
                <div className='strike-through'></div>
                        <div className='about-title'>
                            <h2>Add New User</h2>
                            
                        </div>
                       
                        <small className='small-text'>
                            #24 WhiteHouse Street, Calabar, Cross River State.
                        </small>
                     
                        </div>
                        <div className='about-body form-body'>
                            <div className='form-container'>
                                <form onSubmit={createUser} className='form'>
                                    {success && <div className='suc-msg'> User successfully added</div>}
                                <small className='form-text'>Add using email and password</small>

                                    <input
                                    onChange={e => setUserData(prev => prev = {...prev, name: e.target.value})}
                                    className='fas input-box'
                                    type= "text"
                                    placeholder="Name" 
                                    />
                                    <input
                                    onChange={e => setUserData(prev => prev = {...prev, username: e.target.value})}
                                    placeholder="username"
                                    className='input-box'
                                    type= "text"
                                    />
                                    <input
                                    onChange={e => setUserData(prev => prev = {...prev, password: e.target.value})}
                                    placeholder="Password"
                                    className='input-box'
                                    type= "password"
                                    />
                                    <input
                                    onChange={e => setUserData(prev => prev = {...prev, pswrepeat: e.target.value})}
                                    placeholder="Repeat Password"
                                    className='input-box'
                                    type= "password"
                                    />
                                    <input
                                    value= "CREATE USER"
                                    className='form-btn'
                                    type= "submit"
                                    />


                                </form>
                            </div>
                         

                        </div>
                        </div>
            </div>
           </>
        )
}
export default Register;