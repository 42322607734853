import React from "react";
import { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import axios from "axios";

import '../Pages/booklist/Booklist.css';

const List = ({book}) =>{
    const [showText, setShowText] = useState(false)

    const {id, title, text, author, year, img, pdf} = book;
    // const [idToDisplay, setIdToDisplay] = useState("")

    
    // const displayMore =(e)=>{
    //     setIdToDisplay(e.currentTarget.id)
    //     setShowText(prev => !prev)
        
    // }

 

    
    return(
        
           <div key = {id} className={`list  ${!showText?"curve":""}`}>
            <div className={`l-title`} onClick={() => setShowText(prev => !prev)}>
                <div>
                    <h3>{title}</h3>
                    <span className="small-details">By:<small>{author}</small> </span>

                </div>
                <span  >{showText?<AiOutlineMinus />:<AiOutlinePlus />}</span>
              
            </div>
            <div className={`l-more ${showText?"show":""}`}>
                <div className="inner-img">
                    <img src={`../images/covers/${img}`} />
                </div>
                <p className="l-text" dangerouslySetInnerHTML={{__html:text}}>
                    
                </p>
                <div className="l-meta">
                    <span>{`By: ${author} - ${year}`}  </span>
                </div>
                <a className ="download" href ={`./pdf/${pdf}`} target = "_blank" >View this Book in PDF</a>
            </div>
        </div>
        )
        
        
      
    
}

export default List;