const Teams = [
    {
        id: 1,
        img: "sunny.jpg",
        name: "Mr. Mathias Sunday",
        designation: "MD/CEO"

    },
    {
        id: 2,
        img: "udeme-mgr.jpg",
        name: "Udeme Ekanem",
        designation: "Manager"

    },
    {
        id: 3,
        img: "marvin.jpg",
        name: "Marvin Godwin",
        designation: "Finisher"

    },
    {
        id: 4,
        img: "edwin.jpg",
        name: "Edwin Effiong",
        designation: "Lithographer"

    },
    {
        id: 5,
        img: "ayo.jpg",
        name: "Ayo Asis Musa",
        designation: "Kord Operator"

    },
    {
        id: 6,
        img: "ekpe.jpg",
        name: "Mr. Ekpe Godwin Essien",
        designation: "Kord Operator"

    },
    {
        id: 7,
        img: "precious.jpg",
        name: "Precious Emmanuel",
        designation: "Computer Operator"

    },
    {
        id: 8,
        img: "bibiana.jpg",
        name: "Bebiana Archibong",
        designation: "Cashier"

    },
    {
        id: 9,
        img: "udeme.jpg",
        name: "Udeme Umoh",
        designation: "Finisher"

    },
    {
        id: 3,
        img: "ade.jpg",
        name: "Mr. Ade Sunday Ogundele",
        designation: "Kord Operator"

    },
    {
        id:10,
        img:"friday.jpg",
        name: "Mr. Friday Famous Tom",
        designation: "Kord Operator"
    }
]
export default Teams;