import React from 'react';
import {Link} from 'react-router-dom';

const Card = (props)=>{
    return(


		
				<div className="card">
										

					<img src={`./images/gallery/${props.service.img}`} className="card" alt="..." />
					<div className="card-body">
						<div>
						<h5 className="card-title">{props.service.title}</h5>
						<p className="card-text">{props.service.desc}</p>
						</div>
						<Link to="/services" className="btn-sm btn-primary">See More</Link>
					</div>
					<div className='line'></div>
				</div>
			
			
	



    )
}
export default Card;