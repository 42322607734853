import React from 'react';
import {AiFillTwitterCircle, AiFillPhone, AiFillInstagram, AiFillMail, AiFillFacebook} from 'react-icons/ai';
import {FaFacebook, FaInstagram, FaLocationArrow} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='footer-wrapper'>
                <div className='f-left'>
                    <div className='f-left-upper'>
                        <div className='f-top-left'>
                            <div className='f-logo'>Powered By: SunnyTech Logistic Services</div>
                            <div className='contact-container'>
                                
                                <div className='contact'>
                                    <p><AiFillMail /> emeriego@gmail.com</p>
                                    <p><AiFillPhone /> 08030761163</p>
                                </div>
                             
                                
                            </div>

                        </div>
                        <div className='f-address'>
                            <FaLocationArrow />
                            <span>Visit SunnyTech Head Office Located</span>
                            <p>
                                #94 White House Street, Calabar, Cross River State, Nigeria.
                            </p>
                        </div>
                    </div>
                    <div className='copyright'><span>Copy Right {new Date().getFullYear()} <i>: All Rights Reserved.</i></span></div>
                </div>
                <div className='f-right'>
                <div className='socials'>
                                    
                                    <AiFillTwitterCircle />
                                    <AiFillInstagram />
                                    <FaFacebook />
                                    
                    
                    </div>
                    <div className='links'>
                    <Link to='/'>Home</Link>
                    <Link to='/about'>About SunnyTech</Link>
                    <Link to='/contact'>Contact Us</Link>
                    <Link to='/jobsGallery'>Jobs Gallery</Link>
                    <Link to='/ourTeam'>Our Team</Link>
                    <Link to='/admin'>Admin</Link>
                    <Link to='/admin/login'>Login</Link>

                    <Link to='/booklist'>Published Books</Link>
                    </div>
                </div>
            </div>
        </div>
       
    )
}

export default Footer;