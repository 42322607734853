import React from "react";

const Header = ({title, img, children})=>{
    return(
        <div className="header-wrapper">
            
            <div className="header-container">
                
                <div className="header-img-container">
                    <img src={img} alt="" className="header-img" />

                </div>
                <div className="header-content">
                    <h2 className="header-title">{title}</h2>
                    {children}
                </div>
            </div>
            
        </div>
    )
}
export default Header;