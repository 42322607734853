import React from "react";
import Header from "../../Components/Header";
import headerImage from '../../assets/headerImages/header9.jpg';
import Team from '../../Components/Team';
import Teams from '../../Teams';
import {Link} from 'react-router-dom';
import './OurTeam.css'

const OurTeam = () => {
    return(
        <>
        <Header 
        title="Our Team"
        img={headerImage}
    >
           <p className="h-text">
           We deliver creative Print & Marketing Solutions that make our clients successful. We keep our promises, provide creative solutions, and achieve our client’s goals.        
        </p>
        <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

    </Header> 
    <div className='about-container'>
    <div className='about-wrapper'>
        <div className='about-header'>
            <div className='strike-through'>

            </div>
            <div className='about-title'>
                <h2>Our Team</h2>
                
            </div>
            <small className='small-text'>
                Service with Integrity… Giving Back
                </small>
        </div>
        <div className='about-body team-body'>
            {
                Teams.map(team =>{

                return(
                <Team 
                    team = {team}
                    teamCard = "teamCard"
             

                />
                )})
            }
        </div>
        </div>
        
</div>   
    </>
    
    )
}
export default OurTeam;