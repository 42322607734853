import React from 'react';
import {Outlet} from 'react-router-dom';
import NavBar from '../Components/navBar/NavBar';
import Footer from '../Components/footer/Footer';
import Header from '../Components/Header';

const AppLayout = () => {
    return (
        <>
            <NavBar />
            {/* <Header /> */}
            <Outlet />
            <Footer />
        </>
    

    )
}
export default AppLayout;