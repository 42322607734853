import './Home.css';
import React, { useState } from 'react';
import Banner from '../../assets/sun.png';
import Card from '../../Components/Card';
import Data from '../../data';
import Book from '../../Components/Book';
import {Link} from 'react-router-dom';




const Home = () => {
    const [className, setClassName]=useState("");
    
    return (
        <div className='home'>
        <section className="home-container">
            <div className="banner-header">
                <img src={Banner} width='300px' alt="" className="banner" />
                
            </div>
            <div className="left-header">
                <span className="hashtag">A Seamless Representation of Quality Prints</span>
                <h2 className="bold-text">SUNNYTECH <br />LINK AND LOGISTICS</h2>
                <p className="text">
                    Bringing to your doorstep a new phase in prining technology.
                    We are located at No. 94 White Street, Calabar, Cross River State.
                    
                </p>
                <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

            </div>
            
        </section>
        <section className='home-products'>
        <h2 className='title'> Services</h2>

            <div className='products'>


                {
                    Data.map(service => {
                        return(
                            <>
                            <Card
                            key={service.id}
                                service ={service}
                                className ={className}
                            />
                            </>
                        )
                    })

                }

            </div>
        </section>
        <section className='home-books'>

            <div className='books'>
                
                <Book />
        </div>
        </section>
        </div>
    )
}
export default Home;