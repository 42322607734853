import React from "react";
import { Link } from "react-router-dom";

const Picture = (props)=>{

    return(
        <div className={`card ${props.pictureClass}`}>
										

					<img src={`../images/gallery/${props.job}`} className="card" alt="..." />
					
					<div className='line'></div>
		</div>
    )
}
export default Picture;