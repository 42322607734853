import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Admin.css'

const Admin = () =>{
    const navigate = useNavigate();
    const [auth, setAuth] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [username, setUsername] = useState('');
    const [bookDetails, setBookDetails] = useState('');
    const [success, setSuccess] = useState(false);
    const [text, setText] = useState('');
    // const dd = " <p>vjghjhgjhjhuh  khg j fig</p>";

    axios.defaults.withCredentials = true;
 
    
        useEffect(()=>{

        //use axios to check with the server if user has a set cookie
        //wrap it in useEffect so that it is triggered once.
            axios.get('https://api.sunnytechlink.com/xxx')
            .then(res =>{
                if(res.data.msg ==="authorised user"){
                    setAuth(true)
                    setStatusMsg(res.data.msg)
                    setUsername(res.data.username)
                } else {
                    setAuth(false);
                    navigate('/admin')
                    setStatusMsg(res.data.msg)

                }
            })
            .then(error =>{
              console.log(error)
            })
        }, []);

        const logoutHandler =()=>{
            axios.get('https://api.sunnytechlink.com/logout')
            .then(res => {
                setStatusMsg(res.data.msg)

                window.location.reload(true);

            }).catch(err => console.log(err))      
        }

        const createBook = (e) =>{
            e.preventDefault();
    
            
                axios.post('https://api.sunnytechlink.com/add-book', {...bookDetails, text:text})
                .then(res => {
                    if(res.data.dataStatus === "ok"){
                        // navigate('/admin/login')
                        setSuccess(true)
                        console.log(res.data.statusMsg)
    
                    }else{
                        alert("Error: Could not communicate with the server")
    
                    }
    
                })
                .then(err => console.log(err));
    
            
        }
    
    return (
    <>
    {
        //check if authenticated by checking cookies
        //if not authenticated redirect to login.
        auth?
        <div>
            







            <div className='about-container admin-container'>
            <div className="panel-banner">
                <small>Hello {username}, Welcome Back To Admin Panel</small>
                {/* <h4>{statusMsg}</h4> */}
                <button onClick={logoutHandler} className="logout"> Logout</button>
            </div>
                <div className='admin-wrapper'>
                
                    <div className='about-header'>
                        <div className='strike-through'></div>
                        <div className='admin-title'>
                            <h3>Add New Book</h3>
                            {/* <div className="dd" dangerouslySetInnerHTML = {{__html: dd}} /> */}

                        </div>
                     
                    </div>
                        <div className='about-body add-book-form'>
                            <div className='form-container'>

                                <form onSubmit={createBook} className='form admin-form'>
                                    {success && <div className='suc-msg'> Book successfully added</div>}
                                <small className='form-text'>Add only published books</small>

                                    <input
                                    onChange={e => setBookDetails(prev => prev = {...prev, title: e.target.value})}
                                    className='fas input-box'
                                    type= "text"
                                    placeholder="Book Title"
                                    />
                                    <input
                                    onChange={e => setBookDetails(prev => prev = {...prev, year: e.target.value})}
                                    placeholder="Published Year"
                                    className='input-box'
                                    type= "number"
                                    />
                                    <input
                                    onChange={e => setBookDetails(prev => prev = {...prev, author: e.target.value})}
                                    placeholder="Book Author"
                                    className='input-box'
                                    type= "text"
                                    />
                                    <div className="quill">
                                    {/* <small className="text-label">Book Intro Text</small> */}
                                    {/* <textarea 
                                    onChange={e => setBookDetails(prev => prev = {...prev, text: e.target.value})}
                                    className = 'textarea'
                                    
                                    ></textarea> */}
                                    <ReactQuill theme="snow" placeholder="Content Goes Here.." value = {text} onChange={setText} />
                                    </div>
                                    <input
                                    value= "ADD BOOK"
                                    className='form-btn add-book-btn'
                                    type= "submit"
                                    />


                                </form>
                            </div>
                         

                        </div>
                        </div>
            </div>






        </div>
        :
        <div className="get-out">
            <h4>{statusMsg}</h4>

            <span>Please <Link to = '/admin/login' className="login"> Login</Link> to continue</span>
        </div>

    }

    </>
    )
}
export default Admin;