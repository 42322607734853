import React from 'react';
import Header from '../../Components/Header';
import headerImage from '../../assets/headerImages/header1.jpg';
import './About.css';
import {Link} from 'react-router-dom';


// Sunny-Tech Link and Logistic Concept is an indigenous Printing/Publishing
// company with its Head Office and Operational Base at: 94 White House Street,
// Calabar – Nigeria. At Sunny-Tech Link and Logistic Concept, printing/publishing
// jobs are carried out with the highest level of professionalism with speedy
// completion while ensuring clients’ optimal satisfactions through our experienced

// personnel/expertise.

// The company prides itself as one of the fastest growing publishing companies in
// Calabar (The Canan City, located in the South-South region of the country) due to
// her high quality and speedy delivery of jobs from start to finish which has been the

// hallmark of her successes.

// The company policies include: CONFIDENTIALITY, HEALTH, SAFETY, AND

// ENVIRONMENTAL PROTECTION POLICY

// OUR VISION

// Delivery of high quality finished products to our teaming and valuable clients

// across the globe.
// OUR MISSION

// To pursue vigorously our vision and become one of the best (if not the best)

// printing/publishing companies in the world over.

// Our management Team includes:
// MD/CEO
// Sunday M. Otiko
// B.Sc. (Calabar), PGDLIS (Calabar), Dip. Info. Tech.
// General Manager:
// Mr. Godwin Ushie Mathias
// B.Ed (Calabar); Dip. Info Tech, SSCE
// Accountant
// Mr. Paul Eze
// HND Accounting , SSCE
// Editorial Team
// Mr. Ubi E. Onen - B.A., English &amp; Lit. Studies
// Mr. Itomo Fidelis Ebong - B. A. English &amp; Lit. Studies

// Graphic Designers
// Mr. Godwin Okon Esidor
// Mrs Emah Mmenyene Bassey
// Kord Machine Operators
// Mr. Godwin Essien Ekpe – HOD
// Mr Ade Sunday Ogundele
// Mr. Friday F. Thomas
// Cutting Machine Operator
// Mr. Udeme Michael Umo
// Lithographer
// Mr. Effiong Edwin Albert




const About = () => {
    return (
        <>
        <Header 
        title="About Us"
        img={headerImage}
    >
        
        
        <p className="h-text">
        We deliver creative Print & Marketing Solutions that make our clients successful. We keep our promises, provide creative solutions, and achieve our client’s goals.        </p>
        <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

    </Header>   
<div className='about-container'>
    <div className='about-wrapper'>
        <div className='about-header'>
            <div className='strike-through'>

            </div>
            <div className='about-title'>
                <h2>Our Story</h2>
                
            </div>
            <small className='small-text'>
                Service with Integrity… Giving Back
                </small>
                
                        <div className='map'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1581.1103334030809!2d8.32124174394795!3d4.947734691426794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1067863cef3e4bcd%3A0xe26b9c0e4ce551d7!2s24%20White%20House%20Street%2C%20540281%2C%20Calabar%2C%20Cross%20River!5e0!3m2!1sen!2sng!4v1680244707860!5m2!1sen!2sng"></iframe>
                        </div>
                        <small className='small'>
                            We are located at #94 WhiteHouse Street, Calabar, Cross River State.
                        </small>
                        
        </div>
        <div className='about-body'>
        <h2 className='small-text'>Where We Are</h2>

        <p>Sunny-Tech Link and Logistic Concept is an indigenous Printing/Publishing
company with its Head Office and Operational Base at: 94 White House Street,
Calabar – Nigeria. At Sunny-Tech Link and Logistic Concept, printing/publishing
jobs are carried out with the highest level of professionalism with speedy
completion while ensuring clients’ optimal satisfactions through our experienced

personnel/expertise.

The company prides itself as one of the fastest growing publishing companies in
Calabar - The Canan City, located in the South-South region of the country - due to
her high quality and speedy delivery of jobs from start to finish which has been the

hallmark of her successes.

The company policies include: CONFIDENTIALITY, HEALTH, SAFETY, AND

ENVIRONMENTAL PROTECTION POLICY
            </p>
        
        <div className='mission'>
        <h2 className='small-text'>Mission</h2>
        <p> Delivery of high quality finished products to our teaming and valuable clients across the globe.
          </p>

        </div>
        <div className='vision'>
        <h2 className='small-text'>Vision</h2>
        <p>At SunnyTech, our vision is to become the leading provider of high-quality printing and publishing services. We strive to provide our clients with innovative solutions that meet their unique needs, while maintaining the highest standards of quality, accuracy, and reliability. We are committed to continuous improvement and investing in the latest technology and training to stay ahead of industry trends and advancements. Our goal is to create lasting relationships with our clients by delivering exceptional value and customer service. We aim to be recognized as a trusted partner in the printing and publishing industry and to be known for our commitment to excellence, integrity, and professionalism."</p>
        </div>
       <div className='value'>
       <h2 className='small-text'>Values</h2>
        <p>Putting people first… Operating with Integrity… Giving Back.
            The PrintWorks philosophy of treating others as we desire to be treated is our Golden Rule of Operations</p>
        
       </div>
     
       <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

        </div>
        </div>
        
</div>
    
    </>
    )
}
export default About;