import React from "react";
import Header from "../../Components/Header";
import headerImage from '../../assets/headerImages/header6.jpg';
import Picture from '../../Components/Picture';
import './JobsGallery.css';
import {Link} from 'react-router-dom';

const JobsGallery = () => {
    const Jobs = ["job1.jpeg", "job2.jpeg", "job3.jpeg","job4.jpeg", "job5.jpeg", "job6.jpeg","job7.jpeg", "job8.jpeg", "job9.jpeg","job10.jpeg", "job11.jpeg", "job12.jpeg"]
    return(
        <>
        <Header 
        title="Gallery"
        img={headerImage}
    >
        <p className="h-text">
        We deliver creative Print & Marketing Solutions that make our clients successful. We keep our promises, provide creative solutions, and achieve our client’s goals.        </p>
        <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

        </Header>
            <div className="gallery-container">
                <div className="gallery-wrapper">
                {

                    Jobs.map((job, index) =>{
                        return(
                            <Picture 
                                key={index}
                                job ={job}
                                pictureClass = "picture"
                                 />
                        )
                    })

                }    
        </div>
    </div>
   
    
    </>
    
    )
}
export default JobsGallery;