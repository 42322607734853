// import React from "react";
// import axios from 'axios';

const Books = [
    {
    id:"55",
    title: "Literature and Society",
    author:"Tonia Umoren",
    img:"06.jpg",
    year:"2009",
    downloadLink:"hjhjhjjjhj",
    text:" Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure voluptatem consequatur ducimus nesciunt culpa maiores minima, et delectus incidunt aliquam obcaecati ea commodi soluta modi omnis, nam a perspiciatis eveniet. Laudantium maiores sit quos est delectus fugiat soluta accusamus, tempora non recusandae, earum error sequi, eos eveniet expedita illo fuga?"        
},
    {
        id:"2",
        title: "Public Procurement, Policies and Procedures",
        author:"CRS and CRSHA",
        img:"job12.jpeg",
        year:"2020",
        downloadLink:"hjhhfcdtuii",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero neque dolore aspernatur. Reiciendis iste neque unde quod, reprehenderit est recusandae harum facere illo molestias?"        
        },
    {
            id:"3",
            title: "Modulas For Beginners and Advanced Braille Learners",
            author:"DR. (Mrs) Josephine Dasel Nanjwan",
            img:"07.jpg",
            year:"2022",
            downloadLink:"hjhjhjjjhj",
            text:" Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure voluptatem consequatur ducimus nesciunt culpa maiores minima, et delectus incidunt aliquam obcaecati ea commodi soluta modi omnis, nam a perspiciatis eveniet. Laudantium maiores sit quos est delectus fugiat soluta accusamus, tempora non recusandae, earum error sequi, eos eveniet expedita illo fuga?"        
    },
    {
        id:"4",
        title: "Science Pictoral Book",
        author:"K. E. Edem",
        img:"03.jpg",
        year:"2019",
        downloadLink:"hjhjhjjjhj",
        text:" Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure voluptatem consequatur ducimus nesciunt culpa maiores minima, et delectus incidunt aliquam obcaecati ea commodi soluta modi omnis, nam a perspiciatis eveniet. Laudantium maiores sit quos est delectus fugiat soluta accusamus, tempora non recusandae, earum error sequi, eos eveniet expedita illo fuga?"        
    },
    {
        id:"5",
        title: "International Journal of Continuing Education and Development Studies (IJCEDS)",
        author:"University of Calabar",
        img:"04.jpg",
        year:"2019",
        downloadLink:"hjhjhjjjhj",
        text:" Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure voluptatem consequatur ducimus nesciunt culpa maiores minima, et delectus incidunt aliquam obcaecati ea commodi soluta modi omnis, nam a perspiciatis eveniet. Laudantium maiores sit quos est delectus fugiat soluta accusamus, tempora non recusandae, earum error sequi, eos eveniet expedita illo fuga?"        
    },
    {
        id:"6",
        title: "Animal Protein, Friend or Foe",
        author:"University of Calabar",
        img:"05.jpg",
        year:"2019",
        downloadLink:"hjhjhjjjhj",
        text:" Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure voluptatem consequatur ducimus nesciunt culpa maiores minima, et delectus incidunt aliquam obcaecati ea commodi soluta modi omnis, nam a perspiciatis eveniet. Laudantium maiores sit quos est delectus fugiat soluta accusamus, tempora non recusandae, earum error sequi, eos eveniet expedita illo fuga?"        
    },
    {
        id:"5",
        title: "A 3-Day CRS Exco and CRSHA",
        author:"University of Calabar",
        img:"09.jpg",
        year:"2019",
        downloadLink:"hjhjhjjjhj",
        text:" Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure voluptatem consequatur ducimus nesciunt culpa maiores minima, et delectus incidunt aliquam obcaecati ea commodi soluta modi omnis, nam a perspiciatis eveniet. Laudantium maiores sit quos est delectus fugiat soluta accusamus, tempora non recusandae, earum error sequi, eos eveniet expedita illo fuga?"        
    }
];

export default Books;