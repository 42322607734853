import React, { useState } from 'react';
import { FaPortrait} from 'react-icons/fa';
import '../Pages/contact/Contact.css';
import axios from 'axios';
import { useNavigate, link } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();

    const [inputData, setInputData] = useState({username: "", password: ""});
    const [auth, setAuth] = useState(false)

    axios.defaults.withCredentials = true;
    
    const authenticate = (e) =>{
        e.preventDefault();
        axios.post('https://api.sunnytechlink.com/login', inputData)
            .then(res => {
                if(res.data.error){
                    console.log(res.data.statusMsg)

                }
               else if(res.data.pswStatus === "correct"){
                    navigate('/admin')
                    console.log(res.data.statusMsg)
                    setAuth(true)

                }else if(res.data.pswStatus === "wrong"){
                    console.log(res.data.statusMsg)
                    setAuth(false)


                }

            })
            .then(err => {
                // alert("Error: Could not communicate with the server")

                console.log(err)
            });

    }

    

    return (
           <>
            <div className='about-container'>
                <div className='about-wrappr login-wrapper'>
                    
                        <div className='about-body form-body'>
                        <div className='about-header'>
                        <div className='strike-through'></div>
                        <div className='about-title'>
                            <h2 className='login-title'>Admin Login</h2>
                            
                        </div>
                       
                        
                      
                        </div>
                            <div className='form-container'>
                                <form onSubmit={authenticate} className='form'>

                                    <input 
                                    onChange={e => setInputData(prev => prev = {...prev, username: e.target.value})}
                                    placeholder="UserName" 
                                    className='input-box' 
                                    type= "text" 
                                    />
                                    <input 
                                    onChange={e => setInputData(prev => prev = {...prev, password: e.target.value})}
                                    placeholder="Password" 
                                    className='input-box' 
                                    type= "text" 
                                    />
                                    <input value= "Login" className='form-btn' type= "submit" />


                                </form>
                            </div>
                         

                        </div>
                        </div>
            </div>
           </>
        )
}
export default Login;