const ServicesData = [
    {
        id: "13",
        title: "Graphics Design",
        desc: "We are creative, skilled, and experienced graphics designers who deliver stunning visual solutions that captivate and engage audiences. ",
        img:"job3.jpeg"

    },
    {
        id:"12",
        title:"General Prints",
        desc:"As a reliable and versatile printer, we provide high-quality printing services for your personal and business needs, delivering exceptional results at competitive pricing.",
        img:"job7.jpeg"
    },
    {
        id:"17",
        title:"Sourvenir Prints",
        desc:"Yes, we brand and sell quality promo items (branded and unbranded) to companies and individuals at affordable rate and guarantees fast delivery (10 working days).",
        img:"job13.jpeg"
    },
    {
        id:"14",
        title:"Books Publishing",
        desc:"As a skilled publisher, We offer comprehensive publishing services that cater to your needs, from editing and design to distribution and marketing, ensuring a polished and successful final product.",
        img:"job11.jpeg"
    },
    {
        id:"5",
        title:"Logistics",
        desc:"Looking for reliable and efficient logistics services? We offer end-to-end solutions for your transportation and supply chain needs, delivering seamless operations and superior customer satisfaction.",
        img:"8.jpeg"
    },
    {
        id:"6",
        title:"Branding",
        desc:"Think of brand identity as that unseen hand which quickly brings your business’ name to the mind of your audience whenever they think about your industry.",
        img:"16.jpeg"
    },

]
export default ServicesData;
