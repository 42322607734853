import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import AppLayout from './Pages/AppLayout'
import Home from './Pages/home/Home';
import About from './Pages/about/About';
import Contact from './Pages/contact/Contact';
import JobsGallery from './Pages/jobsGallery/JobsGallery';
import OurTeam from './Pages/ourTeam/OurTeam';
import Booklist from './Pages/booklist/Booklist';
import AdminLayout from './adminPanel/AdminLayout';
import AdminBookList from './adminPanel/AdminBookList';
import Login from './adminPanel/Login';
import Register from './adminPanel/Register';
import Admin from './adminPanel/Admin';
import Services from './Pages/services/Services';
//Psalm16vs6 password for mongodb

// type in "npm ls" to see list of installed dependencies
//
import NotFound from './Pages/notFound/NotFound';

import './App.css';

const App = () => {
  return(
    <div className='main-container'>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<AppLayout />}>
        <Route index element = {<Home />} />
        <Route path='about' element={<About />} />
        <Route path='contact' element={<Contact />} />
        <Route path='jobsGallery' element={<JobsGallery />} />
        <Route path='ourTeam' element={<OurTeam />} />
        <Route path='booklist' element={<Booklist />} />
        <Route path='services' element={<Services />} />
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route path='/admin' element={<AdminLayout />}>
        <Route index element = {<Admin />} />
        <Route path='login' element = {<Login />} />
        <Route path = 'register' element = {<Register />} />
        <Route path = 'bookList' element = {<AdminBookList />} />
      </Route>
    </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App;
