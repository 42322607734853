import React from "react";
import { Link } from "react-router-dom";

const Team = (props)=>{

    return(
        <div className={`card ${props.teamCard}`}>
										

					<img src={`./images/team/${props.team.img}`} className="card" alt="..." />
					<div className="card-body">
						<div>
						<h5 className="card-title">{props.team.name}</h5>
						<p className="card-text">{props.team.designation}</p>
						</div>
						<span className="teamSocials">
                            
                        </span>
					</div>
					<div className='line'></div>
		</div>
    )
}
export default Team;