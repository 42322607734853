import React from "react";
import Header from "../../Components/Header";

const NotFound = () => {
    return(

 <Header 
                title="404 PAGE NOT FOUND"
            >
                <p>
                </p>
                <button className="btn">Get in Touch</button>

            </Header>    )
}
export default NotFound;