import React from "react";
import Book from "../../Components/Book";
import BooksData from "../../BooksData";
import './Booklist.css';
import List from "../../Components/List";
import Header from "../../Components/Header";
import headeImage from '../../assets/headerImages/header7.jpg';
import { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "../../Components/Pagination";
import {Link} from 'react-router-dom';


const Booklist = () => {
    const [books, setBooks] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);

    //pagination states
    const [postsPerPage, setPostsPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    
    const totalNumberOfPosts = books.length;


    const lastPageIndex = currentPage * postsPerPage;
    const firstPageIndex = lastPageIndex - postsPerPage;
    const booksToDisplay = books.slice(firstPageIndex, lastPageIndex);
    // useEffect(()=>{

    //     //use axios to check with the server if user has a set cookie
    //     //wrap it in useEffect so that it is triggered once.
    //         axios.get("https://api.sunnytechlink.com/books")
    //         .then(res =>{
    //             if(res.data.books.length > 0){
    //                 setIsEmpty(false)
    //                 setBooks(res.data.books)
    //                 console.log(books)
    //             } else {
    //                 setIsEmpty(true);
    //                 // console.log(res.data.msg, res.data.books)


    //             }
    //             // console.log(books)

    //         })
    //         // .then(err =>console.log("error"))
    //     }, []);

    useEffect(()=>{
        setBooks(BooksData)
    },[])


    return(
        <>
            <Header 
                title="Published Books"
                img = {headeImage}
            >
                 <p className="h-text">
                 We deliver creative Print & Marketing Solutions that make our clients successful. We keep our promises, provide creative solutions, and achieve our client’s goals.        </p>
                 <Link to='/contact'> <button className="btn h-btn">Get in Touch</button></Link>

            </Header>
            <section className="book-list">
                <div className="list-container">
                    {
                        
                        booksToDisplay.map((book, index)=>{
                           return(
                            <List
                            key={index}
                            book = {book}
                            currentPage ={currentPage}
                            totalNumberOfPosts = {totalNumberOfPosts}
                            postsPerPage ={postsPerPage}

                            
                            />

                           ) // console.log(book)
                            
                        })
    
                    }
                    
                </div>
                <Pagination 
                    postsPerPage = {postsPerPage}
                    setCurrentPage ={setCurrentPage}
                    totalNumberOfPosts ={totalNumberOfPosts}
                    currentPage = {currentPage}
                />

            </section>
            <section className='home-books2 home-books'>

                <div className='books2 books'>
                
                    <Book />
                </div>
            </section>

        </>
    )
}
export default Booklist;